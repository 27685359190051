import axios from "axios";
import { url_api } from "./config.js";

export function requestApi(path, method, params) {
    if (method === 'post') {
        return axios.post(url_api + path, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        });
    } else if (method === 'get') {
        return axios.get(url_api + path, { params: params });
    } else {
        return Promise.reject(new Error('Méthode de requête invalide'));
    }
}